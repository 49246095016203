import React from "react"
import { useSearchForm } from "../../../hooks/useSearch"

import { getAdditionalText } from "../../../utils/getAdditionalText"

export const withSearchForm = Component => ({ name = "SearchForm" }) => {
  const {
    page: {
      search: { additionalSearchButtonText, additionalSearchPlaceholderText },
    },
  } = getAdditionalText()

  const { handleChange, handleSubmit, handleClear, value } = useSearchForm()

  Component.displayName = name
  return (
    <Component
      value={value}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleClear={handleClear}
      additionalSearchButtonText={additionalSearchButtonText}
      additionalSearchPlaceholderText={additionalSearchPlaceholderText}
    />
  )
}
