import React from "react"

import { withSearchForm } from "./withSearchForm"
import {
  Wrapper,
  InputWrapper,
  Input,
  Search,
  Clear,
  ButtonWrapper,
} from "./SearchFormStyles"
import { StyledButton } from "../../Styled/Button"
import { Icon } from "../../Icon/Icon"

export const SearchForm = withSearchForm(
  ({
    value,
    handleChange,
    handleSubmit,
    handleClear,
    additionalSearchButtonText,
    additionalSearchPlaceholderText,
  }): JSX.Element => {
    return (
      <Wrapper id="hello" onSubmit={handleSubmit}>
        <InputWrapper>
          <Search>
            <Icon name={"search"} width={20} height={20} />
          </Search>
          <Input
            value={value}
            onChange={handleChange}
            placeholder={additionalSearchPlaceholderText}
          />
          {value.length > 0 ? (
            <Clear onClick={handleClear}>
              <Icon name={"cross"} width={12} height={12} />
            </Clear>
          ) : null}
        </InputWrapper>

        <ButtonWrapper>
          <StyledButton type="submit" size="primary" wide>
            {additionalSearchButtonText}
          </StyledButton>
        </ButtonWrapper>
      </Wrapper>
    )
  }
)
