import { useCallback, useState } from "react"
import { navigate } from "gatsby"
import { useSearch } from "@usereactify/search"

import { useApp } from "./useApp"

export const useSearchForm = () => {
  const {
    config: {
      settings: { routes },
    },
    globalState,
  } = useApp()
  const { setSearchTerm } = useSearch()
  const [value, setValue] = useState("")
  const [, dispatch] = globalState

  const handleChange = useCallback(({ target: { value } }) => setValue(value), [
    setValue,
  ])
  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      dispatch({
        type: "HIDE_SEARCH",
      })

      navigate(`${routes.SEARCH}?q=${value}`)
      setSearchTerm(value)
    },
    [dispatch, routes, setSearchTerm, value],
  )

  const handleClear = useCallback(() => setValue(""), [setValue])

  return { value, handleChange, handleSubmit, handleClear }
}
