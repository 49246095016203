import React, { useEffect, useRef, useState } from "react"

import { Title, Header, Stats } from "./SearchStyles"
import { SearchForm } from "./Form/SearchForm"
import { useRoutes } from "../../hooks/useRoutes"
import { useSearch, useResults } from "@usereactify/search"
import { useLocation } from "@reach/router"

export const SearchBanner = () => {
  const location  = useLocation()
  const { getUrlParameter } = useRoutes()
  const { setSearchTerm } = useSearch()
  const [searchTerms, setSearchTerms] = useState(getUrlParameter("q"))
  const { resultStats, loading } = useResults()

  const resultRef = useRef(resultStats?.numberOfResults)
  // handling instant search conflicting with resultStats and loading
  const stats = loading && !resultRef?.current ? "Results loading" : `${resultRef?.current ? resultStats?.numberOfResults === undefined ? resultRef?.current : resultStats?.numberOfResults === 0 ? "No" : resultStats?.numberOfResults : "No"} results found`

  useEffect(() => {
    if (!loading) {
      resultRef.current = resultStats?.numberOfResults
    }
  }, [loading, resultStats])

  useEffect(() => {
    const newSearchTerms = getUrlParameter("q")
    if (newSearchTerms !== searchTerms) {
      setSearchTerms(newSearchTerms)
      setSearchTerm(newSearchTerms)
    }
  }, [location])

  return (
    <>
      <Stats>{stats}</Stats>
      <Header>
        <Title>
          Search results&nbsp;
          {searchTerms?.length > 0 ? `for "${searchTerms}"` : null}
        </Title>
        <SearchForm />
      </Header>
    </>
  )
}
