import React from "react"
import { graphql } from "gatsby"

import { Search as Page } from "../components/Search/Search"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageSearch {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      featuredProductsTitle
      products: _rawProducts(resolveReferences: { maxDepth: 2 })
      collection {
        shopify {
          shopifyHandle
        }
      }
    }
  }
`

const Component = ({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("search_page")

  
  return <Page {...props} {...data} />
}
export default Component
