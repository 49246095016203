import React from "react"
import { ReactifySearchProvider, Sensors } from "@usereactify/search"

import { withSearch } from "./withSearch"
import { SearchResults } from "./Results/SearchResults"
import { SensorWrapper, Wrapper, CarouselWrapper } from "./SearchStyles"

import { ProductsCarousel } from "../Sections/ProductsCarousel/ProductsCarousel"
import { SearchBanner } from "./SearchBanner"

export const Search = withSearch(
  ({
    featuredProductsTitle,
    featuredProducts,
    featuredCollection,
    index,
    shopifyPermanentDomain,
  }): JSX.Element => (
    <ReactifySearchProvider
      index={index}
      shopifyPermanentDomain={shopifyPermanentDomain}
      mode="search"
    >
      <SensorWrapper>
        <Sensors />
      </SensorWrapper>
      <Wrapper>
        <SearchBanner />
        <SearchResults />
        {featuredProducts?.length > 0 ||
        featuredCollection?.shopify?.shopifyHandle?.length > 0 ? (
          <CarouselWrapper>
            <ProductsCarousel
              title={featuredProductsTitle}
              products={featuredProducts}
              id={"related"}
              layout={"normal"}
              collection={featuredCollection}
            />
          </CarouselWrapper>
        ) : null}
      </Wrapper>
    </ReactifySearchProvider>
  ),
)
