import tw, { styled } from "twin.macro"

export const InputWrapper = styled.div`
  ${tw`flex-1 mb-1-6 relative`}
`

export const Input = styled.input`
  ${tw`w-full border border-navy rounded pl-5 py-0 bg-white text-16 leading-1.25 md:text-14 md:leading-1.42 h-4-8`}
  ::placeholder {
    ${tw`text-grey opacity-100`}
  }
`

export const Wrapper = styled.form`
  ${tw`flex flex-col md:flex-row max-w-47 w-full`}
`

export const ButtonWrapper = styled.div`
  ${tw`md:ml-1-6 w-full mb-1-6 md:max-w-11-5`}
`

export const Clear = tw.div`
  absolute top-1-8 right-2
`

export const Search = tw.div`
  absolute top-1-4 left-1-2
`
