import tw, { styled } from "twin.macro"
import { StyledContainer } from "../Styled/Container"

export const SensorWrapper = tw.div`
  hidden
`

export const Wrapper = styled(StyledContainer)`
  ${tw`pt-3-2 md:pt-8 pb-6 md:pb-8`}
`

export const Title = tw.h2`
  font-bold text-20 leading-1.5 mlg:text-35 mlg:leading-1.28 tracking-10 text-center uppercase mb-3-2 mlg:mb-4-8
`

export const Header = tw.section`
  flex flex-col items-center md:pb-8
`

export const Stats = tw.div`
  mb-3-2 md:mb-4-8 leading-1.78 text-14 text-center
`

export const CarouselWrapper = tw.div`
  mt-2 md:mt-4
`

export const Divider = tw.hr`
  w-full text-navy text-opacity-20 my-3
`
