import React from "react"

import { useApp } from "../../hooks/useApp"

export const withSearch = Component => ({ name = "Search", page }) => {
  const {
    config: {
      services: {
        reactify: { index },
        shopify: { defaultShopName }
      },
    },
  } = useApp()

  const { featuredProductsTitle, products, collection } = page || {}

  Component.displayName = name
  return (
    <Component
      featuredProductsTitle={featuredProductsTitle}
      featuredProducts={products.filter(v => Boolean(v))}
      featuredCollection={collection}
      index={index}
      shopifyPermanentDomain={`${defaultShopName}.myshopify.com`}
    />
  )
}
